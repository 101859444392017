/**
 * Template Name: NiceAdmin - v2.2.2
 * Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
 * Author: BootstrapMade.com
 * License: https://bootstrapmade.com/license/
 */
(function () {
    "use strict";

    let loginUrl = document.currentScript.getAttribute('data-login-url');
    let langAll = document.currentScript.getAttribute('data-lang-all');
    let langReload = document.currentScript.getAttribute('data-lang-reload');
    let studyProgramDatatableUrl = document.currentScript.getAttribute('data-study-program-data');
    let hipDatatableUrl = document.currentScript.getAttribute('data-hip-data');
    let evaluateFormDatatableUrl = document.currentScript.getAttribute('data-evaluate-form-data');
    let datatableLangUrl = document.currentScript.getAttribute('data-datatable-lang');

    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
        if (all) {
            select(el, all).forEach(e => e.addEventListener(type, listener))
        } else {
            select(el, all).addEventListener(type, listener)
        }
    }

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }

    /**
     * Sidebar toggle
     */
    if (select('.toggle-sidebar-btn')) {
        on('click', '.toggle-sidebar-btn', function (e) {
            select('body').classList.toggle('toggle-sidebar')
        })
    }

    /**
     * Search bar toggle
     */
    if (select('.search-bar-toggle')) {
        on('click', '.search-bar-toggle', function (e) {
            select('.search-bar').classList.toggle('search-bar-show')
        })
    }

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select('#navbar .scrollto', true)
    const navbarlinksActive = () => {
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
            let section = select(navbarlink.hash)
            if (!section) return
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active')
            } else {
                navbarlink.classList.remove('active')
            }
        })
    }
    window.addEventListener('load', navbarlinksActive)
    onscroll(document, navbarlinksActive)

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    let selectHeader = select('#header')
    if (selectHeader) {
        const headerScrolled = () => {
            if (window.scrollY > 100) {
                selectHeader.classList.add('header-scrolled')
            } else {
                selectHeader.classList.remove('header-scrolled')
            }
        }
        window.addEventListener('load', headerScrolled)
        onscroll(document, headerScrolled)
    }

    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
        const toggleBacktotop = () => {
            if (window.scrollY > 100) {
                backtotop.classList.add('active')
            } else {
                backtotop.classList.remove('active')
            }
        }
        window.addEventListener('load', toggleBacktotop)
        onscroll(document, toggleBacktotop)
    }

    /**
     * Initiate tooltips
     */
    let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    $('#js-check-all').click(function (e) {
        e.preventDefault();
        $(':input[value="agree"]').prop('checked', true);
        $(':input[value="agree_with_comments"]').prop('checked', false);
        $(':input[value="disagree"]').prop('checked', false);
        $('[name="grounds_for_the_statement"]').val('Súhlasím v plnom rozsahu s uvedeným opisom študijného programu a jeho prílohami.');

        alert('Formulár bol predvyplnený.');
    });

    $('#js-check-hip-all').click(function (e) {
        e.preventDefault();
        $(':input[value="agree"]').prop('checked', true);
        $(':input[value="agree_with_comments"]').prop('checked', false);
        $(':input[value="disagree"]').prop('checked', false);
        $('[name="grounds_for_the_statement"]').val('Súhlasím v plnom rozsahu s uvedeným opisom habilitačného a inauguračného konania a jeho prílohami.');

        alert('Formulár bol predvyplnený.');
    });

    let generateDatatableFilter = function (element) {
        if ($(element).hasClass('js-filter')) {
            let title = $(element).text();
            if ($(element).hasClass('js-date')) {
                $(element).html('<input class="form-control form-control-sm" type="date"  placeholder="' + title + '" />');
            } else {
                $(element).html('<input class="form-control form-control-sm" type="text"  placeholder="' + title + '" />');
            }
        }
    }

    let studyProgramTable = $('#study-program-table').DataTable({
        "createdRow": function (row, data, dataIndex) {
        },
        "fnRowCallback": function (nRow, aData, iDisplayIndex) {

            $(nRow).click(function () {
            });

            return nRow;
        },
        "initComplete": function () {
            $('#study-program-table thead th').each(function (i) {
                generateDatatableFilter(this);
            });

            studyProgramTable.columns().every(function () {
                let that = this;

                $('input', this.header()).on('keyup change', function () {
                    if (that.search() !== this.value) {
                        that
                            .search(this.value)
                            .draw();
                    }
                });
            });
        },
        drawCallback: function () {
        },
        order: [
            [
                0,
                "desc"
            ]
        ],
        autoWidth: true,
        editable: true,
        dom: '<"d-flex justify-content-between align-items-center"<"mt-2"B><"float-right"l>>t r<"d-flex justify-content-between align-items-center"<i><p>>',
        deferRender: true,
        processing: true,
        serverSide: true,
        bStateSave: false,
        responsive: true,
        pageLength: 20,
        lengthMenu: [
            [
                -1,
                10,
                20,
                50
            ],
            [
                langAll,
                10,
                20,
                50
            ]
        ],
        ajax: {
            url: studyProgramDatatableUrl,
            type: "GET",
            error: function (xhr, error, thrown) {
                if (xhr.status === 401) {
                    window.location.replace(loginUrl);
                }
            }
        },
        buttons: [
            {
                extend: 'print',
                className: 'border d-none d-sm-inline d-md-inline d-lg-inline',
                customize: function (doc) {
                    doc.content[1].table.widths =
                        Array(doc.content[1].table.body[0].length + 1).join('*').split('');
                },
                exportOptions: {
                    columns: 'th:not(.no-export)',
                    modifier: {
                        search: 'applied',
                        order: 'applied'
                    },
                    format: {
                        header: function (data, column, html) {
                            return data.substring(0, data.indexOf('<'));
                        },
                    }
                },

            },
            {
                text: langReload,
                className: 'border d-none d-sm-inline d-md-inline d-lg-inline',
                action: function (e, dt, node, config) {
                    dt.ajax.reload();
                }
            }
        ],
        language: {
            url: datatableLangUrl,
        },
        columns: [
            {
                data: 'studyProgramCode',
                name: 'studyProgramCode',
                orderable: true
            },
            {
                data: 'name',
                name: 'name',
                orderable: true
            },
            {
                data: 'fieldOfStudyName',
                name: 'fieldOfStudyName',
                orderable: true
            },
            {
                data: 'levelOfStudyName',
                name: 'levelOfStudyName',
                orderable: true
            },
            {
                data: 'formOfStudyName',
                name: 'formOfStudyName',
                orderable: true
            },
            {
                data: 'valid_from',
                name: 'valid_from',
                orderable: true
            },
            {
                data: 'valid_to',
                name: 'valid_to',
                orderable: true
            },
            {
                data: 'approved',
                name: 'approved',
                orderable: true
            },
            {
                data: 'action',
                name: 'action',
                orderable: false,
                searchable: false,
                className: 'd-none d-sm-table-cell d-md-table-cell d-lg-table-cell no-export'
            },
        ],

    });

    let hipTable = $('#hip-table').DataTable({
            "createdRow": function (row, data, dataIndex) {
            },
            "fnRowCallback": function (nRow, aData, iDisplayIndex) {

                $(nRow).click(function () {
                });

                return nRow;
            },
            "initComplete": function () {
                $('#hip-table thead th').each(function (i) {
                    generateDatatableFilter(this);
                });

                hipTable.columns().every(function () {
                    let that = this;

                    $('input', this.header()).on('keyup change', function () {
                        if (that.search() !== this.value) {
                            that
                                .search(this.value)
                                .draw();
                        }
                    });
                });
            },
            drawCallback: function () {
            },
            order: [
                [
                    0,
                    "desc"
                ]
            ],
            autoWidth: true,
            editable: true,
            dom: '<"d-flex justify-content-between align-items-center"<"mt-2"B><"float-right"l>>t r<"d-flex justify-content-between align-items-center"<i><p>>',
            deferRender: true,
            processing: true,
            serverSide: true,
            bStateSave: false,
            responsive: true,
            pageLength: 20,
            lengthMenu: [
                [
                    -1,
                    10,
                    20,
                    50
                ],
                [
                    langAll,
                    10,
                    20,
                    50
                ]
            ],
            ajax: {
                url: hipDatatableUrl,
                type: "GET",
                error: function (xhr, error, thrown) {
                    if (xhr.status === 401) {
                        window.location.replace(loginUrl);
                    }
                }
            },
            buttons: [
                {
                    extend: 'print',
                    className: 'border d-none d-sm-inline d-md-inline d-lg-inline',
                    customize: function (doc) {
                        doc.content[1].table.widths =
                            Array(doc.content[1].table.body[0].length + 1).join('*').split('');
                    },
                    exportOptions: {
                        columns: 'th:not(.no-export)',
                        modifier: {
                            search: 'applied',
                            order: 'applied'
                        },
                        format: {
                            header: function (data, column, html) {
                                return data.substring(0, data.indexOf('<'));
                            },
                        }
                    },

                },
                {
                    text: langReload,
                    className: 'border d-none d-sm-inline d-md-inline d-lg-inline',
                    action: function (e, dt, node, config) {
                        dt.ajax.reload();
                    }
                }
            ],
            language: {
                url: datatableLangUrl,
        },
        columns: [
        {
            data: 'name',
            name: 'name',
            orderable: true
        },
        {
            data: 'field_of_study.name',
            name: 'fieldOfStudy.name',
            orderable: true
        },
        {
            data: 'academic_titles',
            name: 'academic_titles',
            orderable: true
        },
        {
            data: 'valid_from',
            name: 'valid_from',
            orderable: true
        },
        {
            data: 'valid_to',
            name: 'valid_to',
            orderable: true
        },
        {
            data: 'approved',
            name: 'approved',
            orderable: true
        },
        {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            className: 'd-none d-sm-table-cell d-md-table-cell d-lg-table-cell no-export'
        },
    ],

});

    let evaluateFormTable = $('#evaluate-forms-table').DataTable({
        "createdRow": function (row, data, dataIndex) {
        },
        "fnRowCallback": function (nRow, aData, iDisplayIndex) {

            $(nRow).click(function () {
            });

            return nRow;
        },
        "initComplete": function () {
            $('#evaluate-forms-table thead th').each(function (i) {
                generateDatatableFilter(this);
            });

            evaluateFormTable.columns().every(function () {
                let that = this;

                $('input', this.header()).on('keyup change', function () {
                    if (that.search() !== this.value) {
                        that
                            .search(this.value)
                            .draw();
                    }
                });
            });
        },
        drawCallback: function () {
        },
        order: [
            [
                1,
                "desc"
            ]
        ],
        autoWidth: true,
        editable: true,
        dom: '<"d-flex justify-content-between align-items-center"<"mt-2"B><"float-right"l>>t r<"d-flex justify-content-between align-items-center"<i><p>>',
        deferRender: true,
        processing: true,
        serverSide: true,
        bStateSave: false,
        responsive: true,
        pageLength: 20,
        lengthMenu: [
            [
                -1,
                10,
                20,
                50
            ],
            [
                langAll,
                10,
                20,
                50
            ]
        ],
        ajax: {
            url: evaluateFormDatatableUrl,
            type: "GET",
            error: function (xhr, error, thrown) {
                if (xhr.status === 401) {
                    window.location.replace(loginUrl);
                }
            }
        },
        buttons: [
            {
                extend: 'print',
                className: 'border d-none d-sm-inline d-md-inline d-lg-inline',
                customize: function (doc) {
                    doc.content[1].table.widths =
                        Array(doc.content[1].table.body[0].length + 1).join('*').split('');
                },
                exportOptions: {
                    columns: 'th:not(.no-export)',
                    modifier: {
                        search: 'applied',
                        order: 'applied'
                    },
                    format: {
                        header: function (data, column, html) {
                            return data.substring(0, data.indexOf('<'));
                        },
                    }
                },

            },
            {
                text: langReload,
                className: 'border d-none d-sm-inline d-md-inline d-lg-inline',
                action: function (e, dt, node, config) {
                    dt.ajax.reload();
                }
            }
        ],
        language: {
            url: datatableLangUrl,
        },
        columns: [
            {
                data: 'institution.name',
                name: 'institution.name',
                orderable: true
            },
            {
                data: 'year',
                name: 'year',
                orderable: true
            },
            {
                data: 'period',
                name: 'period',
                orderable: true
            },
            {
                data: 'action',
                name: 'action',
                orderable: false,
                searchable: false,
                className: 'd-none d-sm-table-cell d-md-table-cell d-lg-table-cell no-export'
            },
        ],

    });


})();